// import { CloseIcon } from '@chakra-ui/icons';
// import {
//   Box,
//   Button,
//   Flex,
//   FormControl,
//   Image,
//   Input,
//   InputGroup,
//   Modal,
//   ModalBody,
//   ModalContent,
//   ModalOverlay,
//   Progress,
//   Table,
//   Tbody,
//   Td,
//   Text,
//   Th,
//   Thead,
//   Tr,
// } from '@chakra-ui/react';
// import React, { useEffect, useRef, useState } from 'react';
// import discover from '../../assets/discover_tune.png';
// import info from '../../assets/info.png';

// interface VestingModalProps {
//   isOpen: boolean;
//   onClose: () => void;
//   onSubmit: () => void;
//   months: number;
// }

// const monthNames = [
//   'January',
//   'February',
//   'March',
//   'April',
//   'May',
//   'June',
//   'July',
//   'August',
//   'September',
//   'October',
//   'November',
//   'December',
// ];

// const VestingModal: React.FC<VestingModalProps> = ({
//   isOpen,
//   onClose,
//   onSubmit,
//   months,
// }) => {
//   const scrollRef = useRef<HTMLDivElement | null>(null);
//   const [isDragging, setIsDragging] = useState(false);
//   const [startY, setStartY] = useState(0);
//   const [scrollTop, setScrollTop] = useState(0);
//   const [inputValues, setInputValues] = useState<number[]>([]);
//   const [progress, setProgress] = useState(0);

//   useEffect(() => {
//     setInputValues(Array(months).fill(0));
//   }, [months]);

//   const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
//     if (!scrollRef.current) return;
//     setIsDragging(true);
//     setStartY(e.pageY - scrollRef.current.offsetTop);
//     setScrollTop(scrollRef.current.scrollTop);
//   };

//   const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
//     if (!isDragging || !scrollRef.current) return;
//     const y = e.pageY - scrollRef.current.offsetTop;
//     const walk = (y - startY) * 2;
//     scrollRef.current.scrollTop = scrollTop - walk;
//   };

//   const handleMouseUp = () => {
//     setIsDragging(false);
//   };

//   const handleInputChange = (value: string, index: number) => {
//     const newValues = [...inputValues];
//     newValues[index] = Number(value);
//     const total = newValues.reduce((acc, val) => acc + val, 0);
//     setInputValues(newValues);
//     setProgress(total);
//   };

//   const remainingPercentage = 100 - progress;

//   const handleSubmit = () => {
//     onSubmit();
//     onClose();
//   };

//   return (
//     <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
//       <ModalOverlay />
//       <ModalContent
//         width={['90vw', '80vw', '546px', '546px', '546px']}
//         borderRadius="20px"
//         height={['auto', 'auto', 'unset']}
//         background="#FFF"
//         boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
//         maxHeight={['500px', '500px', 'unset']}
//       >
//         <ModalBody padding="0px" position="relative">
//           <Box
//             display="flex"
//             flexDirection="column"
//             gap="14px"
//             width="100%"
//             borderRadius="36px"
//             padding={'22px'}
//           >
//             <Flex flexDirection={'column'} gap={'16px'}>
//               <Flex justifyContent={'space-between'}>
//                 <Flex gap={'8px'}>
//                   <Image src={discover} width={'28px'} height={'28px'} />
//                   <Flex gap={'4px'} alignItems={'center'}>
//                     <Text
//                       color={'#1A1A1A'}
//                       fontSize={'16px'}
//                       fontWeight={'600'}
//                       lineHeight={'20px'}
//                     >
//                       Set custom vesting
//                     </Text>
//                     <Image src={info} width={'28px'} height={'28px'} />
//                   </Flex>
//                 </Flex>
//                 <CloseIcon
//                   top="20px"
//                   right="15px"
//                   position="absolute"
//                   boxSize={4}
//                   onClick={onClose}
//                   cursor="pointer"
//                 />
//               </Flex>
//               <Box>
//                 <Box display={'flex'} justifyContent={'space-between'}>
//                   <Text
//                     color={'#8C8C8C'}
//                     fontSize={'14px'}
//                     fontWeight={'500'}
//                     lineHeight={'20px'}
//                   >
//                     Remaining entries
//                   </Text>
//                   <Text
//                     color={'#0FBF95'}
//                     fontSize={'14px'}
//                     fontWeight={'600'}
//                     lineHeight={'20px'}
//                   >
//                     {remainingPercentage}%
//                   </Text>
//                 </Box>
//                 <Progress
//                   value={progress}
//                   height={'6px'}
//                   borderRadius={'100px'}
//                   background="rgba(226, 226, 226, 1)"
//                   sx={{
//                     '& > div': {
//                       background: '#0FBF95',
//                     },
//                   }}
//                 />
//               </Box>
//             </Flex>
//             <Box
//               ref={scrollRef}
//               overflowX="hidden"
//               maxHeight="365px"
//               onMouseDown={handleMouseDown}
//               onMouseMove={handleMouseMove}
//               onMouseUp={handleMouseUp}
//               onMouseLeave={handleMouseUp}
//               cursor={isDragging ? 'grabbing' : 'grab'}
//             >
//               <Table
//                 variant="unstyled"
//                 display={['none', 'none', 'none', 'table']}
//               >
//                 <Thead
//                   mb="16px"
//                   display="block"
//                   position="sticky"
//                   top="0"
//                   bg="white"
//                   zIndex="1"
//                 >
//                   <Tr
//                     borderRadius="10px"
//                     border="solid 0.5px #E2E2E2"
//                     background="#F6F6F6"
//                     display="flex"
//                     justifyContent="space-between"
//                   >
//                     <Th
//                       border="none"
//                       fontFamily="Montserrat"
//                       color="#1A1A1A"
//                       fontSize="13px"
//                       fontWeight="600"
//                       lineHeight="20px"
//                       padding="6px 14px"
//                       flex="1"
//                       textAlign="start"
//                     >
//                       Month
//                     </Th>
//                     <Th
//                       border="none"
//                       fontFamily="Montserrat"
//                       color="#1A1A1A"
//                       fontSize="13px"
//                       fontWeight="600"
//                       lineHeight="20px"
//                       padding="6px 14px"
//                       flex="1"
//                       textAlign="right"
//                       whiteSpace="nowrap"
//                     >
//                       Percentage
//                     </Th>
//                   </Tr>
//                 </Thead>
//                 <Tbody>
//                   {inputValues.map((value, index) => (
//                     <Tr
//                       key={index}
//                       borderBottom={'0.5px solid #F2F2F2'}
//                       display="flex"
//                       alignItems="center"
//                       justifyContent="space-between"
//                     >
//                       <Td
//                         display={'flex'}
//                         border="none"
//                         padding="14px"
//                         flex="1"
//                         textAlign="center"
//                         justifyContent={'flex-start'}
//                       >
//                         {monthNames[index % 12]}
//                       </Td>
//                       <Td
//                         display={'flex'}
//                         border="none"
//                         padding="14px"
//                         flex="1"
//                         justifyContent={'flex-end'}
//                       >
//                         <FormControl>
//                           <InputGroup justifyContent={'flex-end'}>
//                             <Input
//                               type="number"
//                               placeholder="0%"
//                               backgroundColor="#F2F2F2"
//                               borderRadius="12px"
//                               height="44px"
//                               width={'90px'}
//                               textAlign="end"
//                               justifyContent={'flex-end'}
//                               value={value}
//                               onChange={(e) =>
//                                 handleInputChange(e.target.value, index)
//                               }
//                             />
//                           </InputGroup>
//                         </FormControl>
//                       </Td>
//                     </Tr>
//                   ))}
//                 </Tbody>
//               </Table>
//             </Box>
//             <Box
//               display="flex"
//               gap={['10px', '10px', '14px']}
//               flexDirection={['column-reverse', 'column-reverse', 'row']}
//             >
//               <Button
//                 marginTop={['unset', 'unset', '24px']}
//                 borderRadius="14px"
//                 border="1px solid #8C8C8C"
//                 fontWeight={600}
//                 fontFamily="Montserrat"
//                 fontSize="14px"
//                 width={['auto', 'auto', '50%']}
//                 onClick={onClose}
//               >
//                 Cancel
//               </Button>
//               <Button
//                 color="white"
//                 whiteSpace="break-spaces"
//                 type="submit"
//                 marginTop="24px"
//                 width={['auto', 'auto', '50%']}
//                 borderRadius="14px"
//                 background="#0FBF95"
//                 onClick={handleSubmit}
//                 fontWeight={600}
//                 fontFamily="Montserrat"
//                 fontSize="14px"
//                 _hover={{
//                   color: 'white',
//                   bg: '#0FBF75',
//                 }}
//               >
//                 Submit
//               </Button>
//             </Box>
//           </Box>
//         </ModalBody>
//       </ModalContent>
//     </Modal>
//   );
// };

// export default VestingModal;

import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import discover from '../../assets/discover_tune.png';
import info from '../../assets/info.png';

interface VestingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  months: number;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const VestingModal: React.FC<VestingModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  months,
}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [inputValues, setInputValues] = useState<number[]>([]);
  const [progress, setProgress] = useState(0);
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    setInputValues(Array(months).fill(0));
  }, [months]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!scrollRef.current || !isLargerThan768) return;
    setIsDragging(true);
    setStartY(e.pageY - scrollRef.current.offsetTop);
    setScrollTop(scrollRef.current.scrollTop);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !scrollRef.current || !isLargerThan768) return;
    const y = e.pageY - scrollRef.current.offsetTop;
    const walk = (y - startY) * 2;
    scrollRef.current.scrollTop = scrollTop - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleInputChange = (value: string, index: number) => {
    const newValues = [...inputValues];
    newValues[index] = Number(value);
    const total = newValues.reduce((acc, val) => acc + val, 0);
    setInputValues(newValues);
    setProgress(total);
  };

  const remainingPercentage = 100 - progress;

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        width={['90vw', '80vw', '546px', '546px', '546px']}
        borderRadius="20px"
        height={['auto', 'auto', 'unset']}
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
        maxHeight={['500px', '500px', 'unset']}
        border={'1px solid green'}
      >
        <ModalBody padding="0px" position="relative">
          <Box
            display="flex"
            flexDirection="column"
            gap="14px"
            width="100%"
            borderRadius="20px"
            padding={'22px'}
            boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
            background={'#fff'}
          >
            <Flex flexDirection={'column'} gap={'16px'}>
              <Flex justifyContent={'space-between'}>
                <Flex gap={'8px'}>
                  <Image src={discover} width={'28px'} height={'28px'} />
                  <Flex gap={'4px'} alignItems={'center'}>
                    <Text
                      color={'#1A1A1A'}
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'20px'}
                    >
                      Set custom vesting
                    </Text>
                    <Image src={info} width={'28px'} height={'28px'} />
                  </Flex>
                </Flex>
                <CloseIcon
                  top="20px"
                  right="15px"
                  position="absolute"
                  boxSize={4}
                  onClick={onClose}
                  cursor="pointer"
                />
              </Flex>
              <Box>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Text
                    color={'#8C8C8C'}
                    fontSize={'14px'}
                    fontWeight={'500'}
                    lineHeight={'20px'}
                  >
                    Remaining entries
                  </Text>
                  <Text
                    color={'#0FBF95'}
                    fontSize={'14px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                  >
                    {remainingPercentage}%
                  </Text>
                </Box>
                <Progress
                  value={progress}
                  height={'6px'}
                  borderRadius={'100px'}
                  background="rgba(226, 226, 226, 1)"
                  sx={{
                    '& > div': {
                      background: '#0FBF95',
                    },
                  }}
                />
              </Box>
            </Flex>
            <Box
              ref={scrollRef}
              overflowX="hidden"
              maxHeight="365px"
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
              cursor={isLargerThan768 && isDragging ? 'grabbing' : 'default'}
            >
              <Table variant="unstyled">
                <Thead
                  mb="16px"
                  display="block"
                  position="sticky"
                  top="0"
                  bg="white"
                  zIndex="1"
                >
                  <Tr
                    borderRadius="10px"
                    border="solid 0.5px #E2E2E2"
                    background="#F6F6F6"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Th
                      border="none"
                      fontFamily="Montserrat"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="6px 14px"
                      flex="1"
                      textAlign="start"
                    >
                      Month
                    </Th>
                    <Th
                      border="none"
                      fontFamily="Montserrat"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="6px 14px"
                      flex="1"
                      textAlign="right"
                      whiteSpace="nowrap"
                    >
                      Percentage
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {inputValues.map((value, index) => (
                    <Tr
                      key={index}
                      borderBottom={'0.5px solid #F2F2F2'}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Td
                        display={'flex'}
                        border="none"
                        padding="14px"
                        flex="1"
                        textAlign="center"
                        justifyContent={'flex-start'}
                      >
                        {monthNames[index % 12]}
                      </Td>
                      <Td
                        display={'flex'}
                        border="none"
                        padding="14px"
                        flex="1"
                        justifyContent={'flex-end'}
                      >
                        <FormControl>
                          <InputGroup justifyContent={'flex-end'}>
                            <Input
                              type="number"
                              placeholder="0%"
                              backgroundColor="#F2F2F2"
                              borderRadius="12px"
                              height="44px"
                              width={'90px'}
                              textAlign="end"
                              justifyContent={'flex-end'}
                              value={value}
                              onChange={(e) =>
                                handleInputChange(e.target.value, index)
                              }
                            />
                          </InputGroup>
                        </FormControl>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Box
              display="flex"
              gap={['10px', '10px', '14px']}
              flexDirection={['column-reverse', 'column-reverse', 'row']}
            >
              <Button
                marginTop={['unset', 'unset', '24px']}
                borderRadius="14px"
                border="1px solid #8C8C8C"
                fontWeight={600}
                fontFamily="Montserrat"
                fontSize="14px"
                width={['auto', 'auto', '50%']}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                color="white"
                whiteSpace="break-spaces"
                type="submit"
                marginTop="24px"
                width={['auto', 'auto', '50%']}
                borderRadius="14px"
                background="#0FBF95"
                onClick={handleSubmit}
                fontWeight={600}
                fontFamily="Montserrat"
                fontSize="14px"
                _hover={{
                  color: 'white',
                  bg: '#0FBF75',
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VestingModal;
