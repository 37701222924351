import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Switch,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getClients } from '../../api/InvestorApis/client';
import {
  patchInvestment,
  postInvestment,
} from '../../api/InvestorApis/investments';
import {
  ClientResultsArray,
  InvestmentResultsArray,
  ManageInvestmentModalProps,
} from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';
import { formatDateForInput } from '../../utils/commonFunctions';
import { CustomSelect } from './CustomSelect';
import VestingModal from './Vesting';

export default function ManageModal({
  isOpen,
  selectedClient,
  onClose,
  investmentToEdit,
  onAdded,
}: ManageInvestmentModalProps) {
  const [isVestingModalOpen, setIsVestingModalOpen] = useState(false);
  const [hasVestingPlan, setHasVestingPlan] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<ClientResultsArray | null>(null);
  const [loading, setLoading] = useState(false);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState('automatic');
  const [vestingMonths, setVestingMonths] = useState(0);

  const setClientsList = useAppStore(
    (state: AppStoreState) => state.setClientsList,
  );
  const clientsList = useAppStore((state: AppStoreState) => state.clientsList);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<InvestmentResultsArray>({
    defaultValues: {
      token_address: investmentToEdit ? investmentToEdit?.token_address : '',
      tge_percentage: investmentToEdit ? investmentToEdit?.tge_percentage : '',
      tge: investmentToEdit ? formatDateForInput(investmentToEdit?.tge) : '',
      cliff: investmentToEdit ? investmentToEdit?.cliff : '',
      vesting: investmentToEdit ? investmentToEdit?.vesting : '',
      total_tokens: investmentToEdit ? investmentToEdit?.total_tokens : '',
      total_amount: investmentToEdit ? investmentToEdit?.total_amount : '',
    },
  });

  const vestingValue = watch('vesting');

  useEffect(() => {
    setVestingMonths(Number(vestingValue) || 0);
  }, [vestingValue]);

  useEffect(() => {
    reset({
      token_address: investmentToEdit ? investmentToEdit?.token_address : '',
      tge_percentage: investmentToEdit ? investmentToEdit?.tge_percentage : '',
      tge: investmentToEdit ? formatDateForInput(investmentToEdit?.tge) : '',
      cliff: investmentToEdit ? investmentToEdit?.cliff : '',
      vesting: investmentToEdit ? investmentToEdit?.vesting : '',
      total_tokens: investmentToEdit ? investmentToEdit?.total_tokens : '',
      total_amount: investmentToEdit ? investmentToEdit?.total_amount : '',
    });
  }, [investmentToEdit, reset]);

  const openVestingModal = () => {
    setIsVestingModalOpen(true);
  };

  const closeVestingModal = () => {
    setIsVestingModalOpen(false);
  };

  const handleVestingSubmit = () => {
    setHasVestingPlan(true);
    closeVestingModal();
  };

  const handleSwitchChange = () => {
    setIsSwitchChecked((prev) => !prev);
  };

  const handleFormSubmit = async (data: InvestmentResultsArray) => {
    setLoading(true);
    try {
      if (investmentToEdit) {
        const { ...patchData } = data;
        if (selectedOption && selectedOption?.id) {
          patchData.client = selectedOption?.id;
        } else {
          patchData.client = investmentToEdit?.client?.id;
        }
        const res = await patchInvestment(investmentToEdit?.id, patchData);
        if (res) {
          toast({
            title: 'Investment has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      } else {
        const { tge_percentage, tge, cliff, ...newData } = data as any;
        if (tge_percentage !== '') newData.tge_percentage = data.tge_percentage;
        if (tge !== '') newData.tge = data.tge;
        if (cliff !== '') newData.cliff = data.cliff;
        newData.client = selectedOption?.id;

        const res = await postInvestment(newData);
        if (res) {
          toast({
            title: 'Investment has been added successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAdded();
          reset();
        }
      }
    } catch (error: any) {
      toast({
        title: `Error ${investmentToEdit ? 'updating' : 'adding'} investment.`,
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async (page: number) => {
    try {
      setLoading(true);
      const res = await getClients(page);
      if (res) {
        setClientsList(res);
      }
    } catch (err: any) {
      console.log('Error While Fetch All Users ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients(1);
  }, []);

  useEffect(() => {
    const activeClient = clientsList?.results.filter(
      (client) => client.id === selectedClient,
    );
    setSelectedOption(selectedClient && activeClient ? activeClient[0] : null);
  }, [isOpen, clientsList, selectedClient]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        width={['90vw', '80vw', '546px']}
        borderRadius="20px"
        padding={['24px', '36px']}
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
        maxHeight={['500px', '500px', 'unset']}
        overflowY="scroll"
      >
        <ModalBody padding="0px" position="relative">
          <CloseIcon
            top="0"
            right="0"
            position="absolute"
            boxSize={4}
            onClick={onClose}
            cursor="pointer"
          />

          {loading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Text
                color="#1A1A1A"
                fontFamily="Montserrat"
                fontSize="20px"
                fontWeight="700"
                lineHeight="normal"
              >
                {investmentToEdit ? 'Edit Investment' : 'Add Investment'}
              </Text>

              <Flex justifyContent="space-between" marginTop="14px">
                <Text
                  color="#8C8C8C"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="Montserrat"
                >
                  Set to advisory tokens
                </Text>
                <Switch
                  colorScheme="green"
                  size="lg"
                  isChecked={isSwitchChecked}
                  onChange={handleSwitchChange}
                  sx={{
                    '& .chakra-switch__track[data-checked]': {
                      backgroundColor: '#0FBF95',
                    },
                  }}
                />
              </Flex>

              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                style={{ marginTop: '24px' }}
              >
                <VStack spacing={4} align="stretch">
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px">
                    <FormControl>
                      <FormLabel
                        htmlFor="client"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Select client
                      </FormLabel>
                      <CustomSelect
                        options={
                          selectedClient && clientsList?.results
                            ? clientsList.results.filter(
                                (client) => client.id === selectedClient,
                              )
                            : clientsList?.results || null // Change here
                        }
                        value={null}
                        placeholder="Select client"
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        investmentToEdit={investmentToEdit ?? null}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="token_address"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Token address
                      </FormLabel>
                      <Input
                        id="token_address"
                        {...register('token_address', {
                          required: 'Token address is required',
                        })}
                        placeholder="Enter a token address"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                  </Flex>

                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px">
                    <FormControl>
                      <FormLabel
                        htmlFor="total_tokens"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Total token amount
                      </FormLabel>
                      <Input
                        id="total_tokens"
                        type="number"
                        {...register('total_tokens', {
                          required: 'Total tokens is required',
                        })}
                        placeholder="Amount"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                    {!isSwitchChecked && (
                      <FormControl>
                        <FormLabel
                          htmlFor="total_amount"
                          color="#8C8C8C"
                          fontSize="12px"
                          fontWeight={500}
                        >
                          Total invested (in USD)
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <Box
                              display="flex"
                              width="20px"
                              height="20px"
                              background="#FFF"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="6px"
                              borderRadius="4px"
                              border="1px solid #E2E2E2"
                            >
                              <Text
                                fontFamily="Montserrat"
                                fontSize="12px"
                                color="#8C8C8C"
                                fontWeight="500"
                                lineHeight="20px"
                              >
                                $
                              </Text>
                            </Box>
                          </InputLeftElement>
                          <Input
                            id="total_amount"
                            type="number"
                            {...register('total_amount', {
                              required: 'Total amount is required',
                            })}
                            placeholder="Amount"
                            backgroundColor="#F2F2F2"
                            borderRadius="12px"
                            height="44px"
                            pl="30px"
                          />
                        </InputGroup>
                      </FormControl>
                    )}
                  </Flex>

                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px">
                    <FormControl isInvalid={!!errors.tge_percentage}>
                      <FormLabel
                        htmlFor="tge_percentage"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        TGE percentage (optional)
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <Box
                            display="flex"
                            width="20px"
                            height="20px"
                            background="#FFF"
                            alignItems="center"
                            justifyContent="center"
                            marginTop="6px"
                            borderRadius="4px"
                            border="1px solid #E2E2E2"
                          >
                            <Text
                              fontFamily="Montserrat"
                              fontSize="12px"
                              color="#8C8C8C"
                              fontWeight="500"
                              lineHeight="20px"
                            >
                              %
                            </Text>
                          </Box>
                        </InputLeftElement>
                        <Input
                          id="tge_percentage"
                          type="number"
                          min={1}
                          max={100}
                          step={1}
                          {...register('tge_percentage')}
                          placeholder="0%"
                          backgroundColor="#F2F2F2"
                          borderRadius="12px"
                          height="44px"
                          pl="30px"
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="tge"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        TGE date (Optional)
                      </FormLabel>
                      <Input
                        id="tge"
                        type="datetime-local"
                        {...register('tge')}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                  </Flex>
                  <Flex display={'flex'} flexDirection={'column'} gap={'12px'}>
                    <Text color="#8C8C8C" fontSize="12px" fontWeight={500}>
                      Vesting type
                    </Text>
                    <RadioGroup
                      display={'flex'}
                      gap={'12px'}
                      defaultValue="automatic"
                      onChange={setSelectedValue}
                    >
                      <Radio
                        value="automatic"
                        colorScheme="green"
                        size="md"
                        _checked={{ bg: '#0FBF95', borderColor: '#0FBF95' }}
                      >
                        <Text color="#8C8C8C" fontSize="14px" fontWeight={500}>
                          Automatic
                        </Text>
                      </Radio>
                      <Radio
                        value="custom"
                        colorScheme="green"
                        size="md"
                        _checked={{ bg: '#0FBF95', borderColor: '#0FBF95' }}
                      >
                        <Text color="#8C8C8C" fontSize="14px" fontWeight={500}>
                          Custom
                        </Text>
                      </Radio>
                    </RadioGroup>
                  </Flex>
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    gap="12px"
                    marginTop={'12px !important'}
                  >
                    <FormControl>
                      <FormLabel
                        htmlFor="cliff"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Cliff (optional)
                      </FormLabel>
                      <Input
                        id="cliff"
                        type="number"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="0"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        htmlFor="vesting"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Vesting
                      </FormLabel>
                      <Input
                        id="vesting"
                        type="number"
                        {...register('vesting', {
                          required: 'Vesting required',
                        })}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="0"
                      />
                    </FormControl>
                  </Flex>

                  {selectedValue === 'custom' && (
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={'4px'}
                      >
                        <Text color="#8C8C8C" fontSize="14px" fontWeight={500}>
                          Vesting plan
                        </Text>
                        {hasVestingPlan && (
                          <Box
                            padding={'4px 10px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={' 14px'}
                            background={'#D1F5EB'}
                          >
                            <Text
                              color="#0FBF95"
                              fontSize="14px"
                              fontWeight={500}
                              display="flex"
                              alignItems="center"
                            >
                              Submitted
                            </Text>
                          </Box>
                        )}
                      </Box>
                      <Button
                        marginTop={['unset', 'unset', '24px']}
                        borderRadius="14px"
                        border="1px solid #8C8C8C"
                        fontWeight={600}
                        fontFamily="Montserrat"
                        fontSize="14px"
                        onClick={openVestingModal}
                        width={['fit-content', 'fit-content', 'fit-content']}
                        backgroundColor={'black'}
                        color={'white'}
                      >
                        {hasVestingPlan ? 'Edit' : 'Add'}
                        {/* change to Add again when modal closed */}
                      </Button>
                    </Flex>
                  )}
                  <Box
                    display="flex"
                    gap={['10px', '10px', '14px']}
                    flexDirection={['column-reverse', 'column-reverse', 'row']}
                  >
                    <Button
                      marginTop={['unset', 'unset', '24px']}
                      borderRadius="14px"
                      border="1px solid #8C8C8C"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={onClose}
                      width={['auto', 'auto', '50%']}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width={['auto', 'auto', '50%']}
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      {investmentToEdit ? 'Edit Investment' : 'Add Investment'}
                    </Button>
                  </Box>
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
      <VestingModal
        isOpen={isVestingModalOpen}
        onClose={closeVestingModal}
        onSubmit={handleVestingSubmit}
        months={vestingMonths}
      />
    </Modal>
  );
}
