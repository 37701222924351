import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import React from 'react';
import { fetchClientsInfo, getPayouts } from '../../api/InvestorApis/apis';
import backicon from '../../assets/arrow_back_icon.png';
import ClientOverviewTab from './ClientOverviewTab';
// Define the component without props
const ClientOverview: React.FC = () => {
  const toast = useToast();
  const {
    data: ClientInfo,
    isLoading: clientInfoLoading,
    error: clientInfoError,
  } = useQuery({
    queryKey: ['clientInfo'],
    queryFn: () => fetchClientsInfo(),
  });
  const {
    data: PayoutData,
    isLoading: payoutLoading,
    error: payoutError,
  } = useQuery({
    queryKey: ['payouts'],
    queryFn: () => getPayouts(false, 0),
  });
  const totalInvested = PayoutData?.results.reduce((prev, current) => {
    return prev + parseFloat(current.dollar_value);
  }, 0);
  if (clientInfoLoading || payoutLoading) return <p>Loading...</p>;
  if (clientInfoError || payoutError)
    toast({
      title: 'Something went wrong while fetching payouts',
      description: clientInfoError?.message,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  const paidPayouts = PayoutData?.results.filter((payout) => payout.paid);
  const unpaidPayouts = PayoutData?.results.filter((payout) => !payout.paid);
  const firstFourTokens = ClientInfo?.results?.slice(0, 4);
  return (
    <>
      <Flex
        flexDirection={'column'}
        marginTop={'36px'}
        marginBottom={'24px'}
        gap={['14px', '6px']}
        padding={['0px ', '0px ', '0px 20px']}
      >
        <Box
          padding={'8px 12px'}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          borderRadius={'14px'}
          border={'1px solid #E2E2E2'}
          background={'#FFF'}
          width={'fit-content'}
          onClick={() => window.history.back()}
          cursor={'pointer'}
        >
          <Image src={backicon} alt=" " width={'24px'} height={'24px'} />
          <Text
            color={'#1A1A1A'}
            textAlign={'center'}
            fontSize={'18px'}
            fontWeight={'500'}
            lineHeight={'normal'}
            fontStyle={'normal'}
            fontFamily="Montserrat"
            onClick={() => window.history.back()}
            cursor={'pointer'}
          >
            Back
          </Text>
        </Box>
        <Text
          display={['text', 'text', 'text', 'text']}
          color={'#1A1A1A'}
          textAlign={'left'}
          fontSize={['24px', '24px', '36px']}
          fontWeight={'700'}
          lineHeight={'normal'}
          fontStyle={'normal'}
          width={['371px', 'fit-content']}
          fontFamily="Montserrat"
        >
          Welcome to your client overview! 💼
        </Text>
      </Flex>
      <Flex gap={'14px'} flexDirection={'column'}>
        <Flex gap={'14px'} display={['none', 'none', 'none', 'flex', 'flex']}>
          <Flex
            padding={'22px'}
            gap={'24px'}
            flexDirection={'column'}
            flex={'1 0 0'}
            borderRadius={'24px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Cients
            </Text>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <Text
                color="var(--Color-Neutral-950, #1A1A1A)"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="36px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {ClientInfo?.results.length}
              </Text>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'10px'}
                alignItems={'center'}
              >
                <Box display="flex" alignItems="center" position="relative">
                  {firstFourTokens?.map((token, index) => {
                    return (
                      <Image
                        src={token.client.token_img}
                        alt=" "
                        width="36px"
                        height="36px"
                        position="relative"
                        zIndex={index + 1}
                        marginLeft="-6px"
                      />
                    );
                  })}
                </Box>
                <Text color={'#A6A6A6'} fontSize={'36px'} fontWeight={'500'}>
                  +
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Flex
            padding={'22px'}
            gap={'24px'}
            flexDirection={'column'}
            flex={'1 0 0'}
            borderRadius={'24px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Invested
            </Text>
            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="var(--Color-Neutral-950, #1A1A1A)"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              ${totalInvested?.toFixed(2)}
            </Text>
          </Flex>
          <Flex
            padding={'22px'}
            gap={'24px'}
            flexDirection={'column'}
            flex={'1 0 0'}
            borderRadius={'24px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Payouts
            </Text>
            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="#A6A6A6"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              <span style={{ color: '#0FBF95' }}>{paidPayouts?.length}</span>/
              {unpaidPayouts?.length}
            </Text>
          </Flex>
        </Flex>
        <Flex
          padding={'22px'}
          gap={'24px'}
          flexDirection={'column'}
          flex={'1 0 0'}
          borderRadius={'24px'}
          border={'1px solid #F6F6F6'}
          background={'#FFF'}
          boxShadow={
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
          }
          display={['flex', 'flex', 'flex', 'none', 'none']}
        >
          <Box
            display="flex"
            flexDirection={'column'}
            padding={'16px'}
            borderBottom={'1px solid #F2F2F2'}
            gap={'8px'}
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Cients
            </Text>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <Text
                color="var(--Color-Neutral-950, #1A1A1A)"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="36px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {ClientInfo?.results.length}
              </Text>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'10px'}
                alignItems={'center'}
              >
                <Box display="flex" alignItems="center" position="relative">
                  {firstFourTokens?.map((token, index) => {
                    return (
                      <Image
                        src={token.client.token_img}
                        alt=" "
                        width="36px"
                        height="36px"
                        position="relative"
                        zIndex={index + 1}
                        marginLeft="-6px"
                      />
                    );
                  })}
                </Box>
                <Text color={'#A6A6A6'} fontSize={'36px'} fontWeight={'500'}>
                  +
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            padding={'16px'}
            borderBottom={'1px solid #F2F2F2'}
            gap={'8px'}
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Invested
            </Text>
            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="var(--Color-Neutral-950, #1A1A1A)"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              ${totalInvested?.toFixed(2)}
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection={'column'}
            padding={'16px'}
            gap={'8px'}
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Payouts
            </Text>
            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="#A6A6A6"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              <span style={{ color: '#0FBF95' }}>{paidPayouts?.length ?? 0}</span>/{unpaidPayouts?.length}
            </Text>
          </Box>
        </Flex>
        <ClientOverviewTab clientInfo={ClientInfo} />
      </Flex>
    </>
  );
};

export default ClientOverview;
