import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Img,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import schedule from '../../assets/schedule.png';
import { PayoutResultsArray } from '../../interfaces/components';
import ClaimTokenModal from '../claimToken/ClaimTokenModal';
import { hasDatePassed } from '../../utils/commonFunctions';

const PayoutComp: React.FC<{ payouts: PayoutResultsArray[] }> = ({
  payouts,
}) => {
  // const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  // const openRow = (index: number) => {
  //   setExpandedRowIndex(expandedRowIndex === index ? null : index);
  // };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payloadId, setPayloadId] = useState('');

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 6; // Adjust this value as needed

  const openRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPayloadId('');
    setIsModalOpen(false);
  };

  const totalPages = Math.ceil(payouts.length / rowsPerPage);

  const paginatedData = payouts?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage,
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const items = [];

    const renderPageNumber = (page: number) => (
      <Box
        key={page}
        background={page === currentPage ? '#0FBF95' : 'white'}
        borderRadius="full"
        padding={'0px 6px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="20px"
          color={page === currentPage ? 'white' : '#8C8C8C'}
          cursor="pointer"
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Text>
      </Box>
    );

    items.push(renderPageNumber(1));
    if (totalPages > 1) {
      items.push(renderPageNumber(2));
    }

    if (currentPage > 3 && totalPages > 3) {
      items.push(
        <Text key="dots1" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (currentPage > 2 && currentPage < totalPages) {
      items.push(renderPageNumber(currentPage));
    }

    if (currentPage < totalPages - 1 && totalPages > 3) {
      items.push(
        <Text key="dots2" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (totalPages > 2) {
      items.push(renderPageNumber(totalPages));
    }

    return items;
  };


  return (
    <>
      <ClaimTokenModal
        isOpen={isModalOpen}
        onClose={closeModal}
        payoutId={payloadId}
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={['24px', '24px', '24px', '21px']}
        background={'rgba(255, 255, 255, 1)'}
        borderBottomRadius={'44px'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        border={'1px solid rgba(255, 255, 255, 1)'}
        padding={['16px', '16px', '16px', '22px']}
        borderTop={['1px solid rgba(255, 255, 255, 1)']}
      >
        <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Client
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Token Amount
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Dollar Value
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Invested
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Payout Part
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'right'}
              >
                Next payout
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((data, index) => (
              <Tr
                key={data.id}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 6 === 0 ? '0px' : '20px'}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Td border="none" padding="14px" flex="1">
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Img
                      src={data.client.token_img}
                      alt=""
                      height={'36px'}
                      width={'36px'}
                    />
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  {data.amount}
                </Td>
                <Td
                  border="none"
                  padding="14px"
                  flex="1"
                  gap={'8px'}
                  display={'flex'}
                  flexDirection={'row'}
                >
                  <Box
                    width={'20px'}
                    height={'20px'}
                    padding={'0px 4px'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    borderRadius={'6px'}
                    border={'1px solid #0FBF95'}
                    background={'#0FBF95'}
                    color={'#FFF'}
                    display={'flex'}
                  >
                    <Text
                      fontSize={'12px'}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                    >
                      $
                    </Text>
                  </Box>
                  {(parseInt(data.amount) * (data.client.token_price ?? 0)).toFixed(2)}
                </Td>
                <Td border="none" padding="14px" flex="1">
                  <Text>${data.investment.total_amount}</Text>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  <Flex flexDirection={'column'}>
                    <Text
                      color={'#8C8C8C'}
                      fontSize={'12px'}
                      fontWeight={'600'}
                      lineHeight={'20px'}
                    >
                      {data.payout_part} of {data.total_payouts}
                    </Text>
                    <Progress
                      width={'40%'}
                      value={(data.payout_part / data.total_payouts) * 100}
                      height={'6px'}
                      borderRadius={'100px'}
                      background="rgba(226, 226, 226, 1)"
                      sx={{
                        '& > div': {
                          background: '#0FBF95',
                        },
                      }}
                    />
                  </Flex>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  {/* if data.bttn is equal to false  */}

                  {!hasDatePassed(data.date) ? (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      padding={'10px 18px'}
                      justifyContent={'center'}
                      alignItems={'flex-start'}
                      gap={'5px'}
                      borderRadius={'22px'}
                      border={'1px solid #E2E2E2'}
                      background={'#FFF'}
                      width={'100%'}
                    >
                      <Flex alignItems={'center'} gap={'7px'}>
                        <Image src={schedule} width={'20px'} height={'20px'} />
                        <Text>{data.date}</Text>
                      </Flex>
                      <Progress
                        width={'100%'}
                        value={0}
                        height={'6px'}
                        borderRadius={'100px'}
                        background="rgba(226, 226, 226, 1)"
                        sx={{
                          '& > div': {
                            background: '#0FBF95',
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        onClick={() => {
                          setPayloadId(data.id.toString());
                          openModal();
                        }}
                        disabled={data.paid}
                        display={'flex'}
                        flexDirection={'row'}
                        padding={'4px 8px'}
                        borderRadius={'12px'}
                        border={'1px solid #E2E2E2'}
                        height={'28px'}
                        gap={'10px'}
                        background={'#1A1A1A'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Text
                          fontSize={'13px'}
                          fontWeight={'600'}
                          color={'#FFF'}
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                        >
                          Claim Tokens
                        </Text>
                      </Button>
                    </Box>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Table variant="unstyled" display={['table', 'table', 'table', 'none']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                position="relative"
              >
                Client
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '15%',
                    bottom: '15%',
                    width: '1px',
                    background: '#E2E2E2',
                  }}
                />
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'end'}
                whiteSpace={'nowrap'}
              >
                Claim Amount
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((data, index) => (
              <Tr
                key={index}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 6 === 0 ? '0px' : '20px'}
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="20px 20px 14px 20px"
                  onClick={() => openRow(index)}
                  cursor="pointer"
                >
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Image
                      src={data.client.token_img}
                      alt=""
                      height={'36px'}
                      width={'36px'}
                    />
                    <Text
                      fontSize={'13px'}
                      fontWeight={'500'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Text
                      fontSize={'12px'}
                      fontWeight={'500'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.amount}
                    </Text>
                    <ChevronDownIcon
                      color="#8C8C8C"
                      boxSize={6}
                      transform={
                        expandedRowIndex === index ? 'rotate(180deg)' : 'unset'
                      }
                      transition="0.2s all"
                    />
                  </Flex>
                </Box>

                <Collapse in={expandedRowIndex === index} animateOpacity>
                  <Box padding="0px 50px 14px 20px">
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Dollar Value
                      </Text>
                      <Flex gap={'8px'} alignItems={'center'}>
                        <Box
                          width={'20px'}
                          height={'20px'}
                          padding={'0px 4px'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          borderRadius={'6px'}
                          border={'1px solid #0FBF95'}
                          background={'#0FBF95'}
                          color={'#FFF'}
                          display={'flex'}
                        >
                          <Text
                            fontSize={'12px'}
                            fontWeight={'700'}
                            lineHeight={'20px'}
                            fontFamily="Montserrat"
                          >
                            %
                          </Text>
                        </Box>
                        <Text
                          fontSize={'13px'}
                          fontWeight={'500'}
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                          fontStyle={'normal'}
                        >
                          {data.investment.tge_percentage}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Invested
                      </Text>
                      <Text
                        color={'#8C8C8C'}
                        fontSize={'13px'}
                        fontWeight={'500'}
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                        fontStyle={'normal'}
                      >
                        {data.amount}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text
                        color="#8C8C8C"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight={'20px'}
                        fontFamily="Montserrat"
                      >
                        Payout Part
                      </Text>
                      <Flex flexDirection={'column'} alignItems="flex-end">
                        <Text
                          color={'#8C8C8C'}
                          fontSize={'12px'}
                          fontWeight={'600'}
                          lineHeight={'20px'}
                        >
                          {data.payout_part} of {data.total_payouts}
                        </Text>
                        <Progress
                          width={'50px'}
                          value={(data.payout_part / data.total_payouts) * 100}
                          height={'6px'}
                          borderRadius={'100px'}
                          background="rgba(226, 226, 226, 1)"
                          sx={{
                            '& > div': {
                              background: '#0FBF95',
                            },
                          }}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                </Collapse>

                <Box
                  borderTop={'1px solid #E2E2E2 '}
                  padding="14px 20px 20px 20px"
                  alignItems={'center'}
                  display={'flex'}
                  justifyContent={'space-between'}
                >
                  <Text
                    color="#8C8C8C"
                    fontSize="13px"
                    fontWeight="500"
                    lineHeight={'20px'}
                    fontFamily="Montserrat"
                  >
                    Next Payout
                  </Text>
                  {hasDatePassed(data.date) ? (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      padding={'10px 18px'}
                      justifyContent={'center'}
                      alignItems={'flex-start'}
                      gap={'5px'}
                      borderRadius={'22px'}
                      border={'1px solid #E2E2E2'}
                      background={'#FFF'}
                      width={'160px'}
                    >
                      <Flex alignItems={'center'} gap={'7px'}>
                        <Image src={schedule} width={'20px'} height={'20px'} />
                        <Text
                          fontSize="13px"
                          fontWeight="500"
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                        >
                          {data.investment.total_amount}
                        </Text>
                      </Flex>
                      <Progress
                        width={'100%'}
                        value={0}
                        height={'6px'}
                        borderRadius={'100px'}
                        background="rgba(226, 226, 226, 1)"
                        sx={{
                          '& > div': {
                            background: '#0FBF95',
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <Button
                        onClick={() => {
                          setPayloadId(data.id.toString());
                          openModal();
                        }}
                        disabled={data.paid}
                        display={'flex'}
                        flexDirection={'row'}
                        padding={'4px 8px'}
                        borderRadius={'12px'}
                        border={'1px solid #E2E2E2'}
                        height={'28px'}
                        gap={'10px'}
                        background={'#1A1A1A'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Text
                          fontSize={'13px'}
                          fontWeight={'600'}
                          color={'#FFF'}
                          lineHeight={'20px'}
                          fontFamily="Montserrat"
                        >
                          Claim Tokens
                        </Text>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <HStack
        spacing="6px"
        padding={'16px'}
        borderRadius={'44px'}
        border={'1px solid #EFEFEF'}
        background={'#FFF'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        width={'fit-content'}
        alignSelf={'center'}
        marginTop={'20px'}
      >
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage <= 1}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />

        {renderPageNumbers()}

        <IconButton
          aria-label="Next page"
          icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage >= totalPages}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />
      </HStack>
    </>
  );
};

export default PayoutComp;
