import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Img,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Liquidate } from '../../interfaces/components';
import { formateDate } from '../../utils/commonFunctions';

const LiquidationTable: React.FC<{
  liquidateData: Liquidate | undefined;
  fetchLiquidateData: (page: number) => Promise<void>;
}> = ({  liquidateData,fetchLiquidateData }) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5
  const totalPages = (liquidateData?.total_pages ?? 0)*2 
 
  const startIdx = (currentPage % 2) ? 0: rowsPerPage;
  const paginatedData = liquidateData?.results.slice(startIdx, startIdx + rowsPerPage);
  const openRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handlePageChange: (page: number) => void = (page) => {
    if(currentPage %2 ===0 ){
      setCurrentPage(page)
      fetchLiquidateData(Math.floor(page /2) +1);
    }
    else{
      setCurrentPage(page)
    }
  };
  const renderPageNumbers = () => {
    const items = [];

    const renderPageNumber = (page: number) => (
      <Box
        key={page}
        background={page === currentPage ? '#0FBF95' : 'white'}
        borderRadius="full"
        padding={'0px 6px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="20px"
          color={page === currentPage ? 'white' : '#8C8C8C'}
          cursor="pointer"
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Text>
      </Box>
    );

    items.push(renderPageNumber(1));
    if (totalPages > 1) {
      items.push(renderPageNumber(2));
    }

    if (currentPage > 3 && totalPages > 3) {
      items.push(
        <Text key="dots1" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (currentPage > 2 && currentPage < totalPages) {
      items.push(renderPageNumber(currentPage));
    }

    if (currentPage < totalPages - 1 && totalPages > 3) {
      items.push(
        <Text key="dots2" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (totalPages > 2) {
      items.push(renderPageNumber(totalPages));
    }

    return items;
  };
  return (
    <>
      
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={['24px', '24px', '24px', '21px']}
        background={'rgba(255, 255, 255, 1)'}
        borderBottomRadius={'44px'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        border={'1px solid #F6F6F6'}
        borderTop={'transparent'}
        padding={['16px', '16px', '16px', '16px', '22px']}
      >
        <Table variant="unstyled" display={['none', 'none', 'none', 'table']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Client
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Sold for (USD)
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Claim Value (USD)
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
              >
                Profit (USD)
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                justifyContent={'flex-end'}
                display={'flex'}
              >
                Date
              </Th>

              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'right'}
              >
                Token Amount
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((data, index) => (
              <Tr
                key={index}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 5 === 0 ? '0px' : '20px'}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Td border="none" padding="14px" flex="1">
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Img
                      src={data.client.token_img}
                      alt=""
                      height={'36px'}
                      width={'36px'}
                    />
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                </Td>
                <Td border="none" padding="14px" flex="1">
                  ${data.sell_value}
                </Td>
                <Td
                  border="none"
                  padding="14px"
                  flex="1"
                  gap={'8px'}
                  display={'flex'}
                  flexDirection={'row'}
                >
                  <Box
                    width={'20px'}
                    height={'20px'}
                    padding={'0px 4px'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    borderRadius={'6px'}
                    border={'1px solid #0FBF95'}
                    background={'#0FBF95'}
                    color={'#FFF'}
                    display={'flex'}
                  >
                    <Text
                      fontSize={'12px'}
                      fontWeight={'700'}
                      lineHeight={'20px'}
                      fontFamily="Montserrat"
                    >
                      $
                    </Text>
                  </Box>
                  {data.claim_value}
                </Td>

                <Td border="none" padding="14px" flex="1">
                  <Text
                    display={'flex'}
                    color={
                      parseFloat(data.sell_value) -
                        parseFloat(data.claim_value) >
                      0
                        ? '#1A1A1A'
                        : '#DC3545'
                    }
                  >
                    {`${
                      parseFloat(data.sell_value) -
                        parseFloat(data.claim_value) <
                      0
                        ? '-'
                        : ''
                    }$${Math.abs(
                      parseFloat(data.sell_value) -
                        parseFloat(data.claim_value),
                    )}`}
                  </Text>
                </Td>
                <Td
                  border="none"
                  padding="14px"
                  flex="1"
                  justifyContent={'flex-end'}
                  display={'flex'}
                >
                  {formateDate(data.liquidate_date)}
                </Td>
                <Td border="none" padding="14px" flex="1">
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    {/* {data.bar} */}
                    {data.token_amount}
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Table variant="unstyled" display={['table', 'table', 'table', 'none']}>
          <Thead mb="16px" display={'block'}>
            <Tr
              borderRadius={'10px'}
              border={'solid 0.5px #E2E2E2'}
              background={'#F6F6F6'}
              display="flex"
              justifyContent="space-between"
            >
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                position="relative"
              >
                Client
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '15%',
                    bottom: '15%',
                    width: '1px',
                    background: '#E2E2E2',
                  }}
                />
              </Th>
              <Th
                border="none"
                fontFamily="Montserrat"
                color={'#1A1A1A'}
                fontSize={'13px'}
                fontWeight={'600'}
                lineHeight={'20px'}
                padding={'6px 14px'}
                flex="1"
                textAlign={'end'}
                whiteSpace={'nowrap'}
              >
                Profit (USD)
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {paginatedData?.map((data, index) => (
              <Tr
                key={index}
                borderRadius="36px"
                background="#F6F6F6"
                mb={(index + 1) % 5 === 0 ? '0px' : '20px'}
                display="flex"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="20px 20px 14px 20px"
                  onClick={() => openRow(index)}
                  cursor="pointer"
                >
                  <Flex alignItems={'center'} gap={'8px'}>
                    <Image
                      src={data.client.token_img}
                      alt=""
                      height={'36px'}
                      width={'36px'}
                    />
                    <Text
                      fontSize={'16px'}
                      fontWeight={'600'}
                      lineHeight={'normal'}
                      fontFamily="Montserrat"
                      color={'#1A1A1A'}
                    >
                      {data.client.name}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Text
                      display={'flex'}
                      color={
                        parseFloat(data.sell_value) -
                          parseFloat(data.claim_value) >
                        0
                          ? '#1A1A1A'
                          : '#DC3545'
                      }
                    >
                      {`${
                        parseFloat(data.sell_value) -
                          parseFloat(data.claim_value) <
                        0
                          ? '-'
                          : ''
                      }$${Math.abs(
                        parseFloat(data.sell_value) -
                          parseFloat(data.claim_value),
                      )}`}
                    </Text>

                    <ChevronDownIcon
                      color="#8C8C8C"
                      boxSize={6}
                      transform={
                        expandedRowIndex === index ? 'rotate(180deg)' : 'unset'
                      }
                      transition="0.2s all"
                    />
                  </Flex>
                </Box>

                <Collapse in={expandedRowIndex === index} animateOpacity>
                  <Box padding="0px 50px 14px 20px">
                    <Flex justifyContent="space-between" mb="10px">
                      <Text color="#8C8C8C" fontSize="13px" fontWeight="500">
                        Sold for (USD)
                      </Text>
                      <Text
                        display={'flex'}
                        color={'#8C8C8C'}
                        fontSize={'12px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        alignSelf={'flex-end'}
                        fontFamily="Montserrat"
                      >
                        ${data.sell_value}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text color="#8C8C8C" fontSize="13px" fontWeight="500">
                        Claim Value (USD)
                      </Text>
                      <Flex gap={'8px'} alignItems={'center'}>
                        <Box
                          width={'20px'}
                          height={'20px'}
                          padding={'0px 4px'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          borderRadius={'6px'}
                          border={'1px solid #0FBF95'}
                          background={'#0FBF95'}
                          color={'#FFF'}
                          display={'flex'}
                        >
                          <Text
                            fontSize={'12px'}
                            fontWeight={'700'}
                            lineHeight={'20px'}
                            fontFamily="Montserrat"
                          >
                            $
                          </Text>
                        </Box>
                        <Text
                          display={'flex'}
                          fontSize={'12px'}
                          fontWeight={'600'}
                          lineHeight={'20px'}
                          alignSelf={'flex-end'}
                          fontFamily="Montserrat"
                        >
                          {data.claim_value}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text color="#8C8C8C" fontSize="13px" fontWeight="500">
                        Token Amount
                      </Text>
                      <Text
                        display={'flex'}
                        color={'#8C8C8C'}
                        fontSize={'12px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        alignSelf={'flex-end'}
                        fontFamily="Montserrat"
                      >
                        {data.token_amount}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" mb="10px">
                      <Text color="#8C8C8C" fontSize="13px" fontWeight="500">
                        Date
                      </Text>
                      <Text
                        display={'flex'}
                        color={'#8C8C8C'}
                        fontSize={'12px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        alignSelf={'flex-end'}
                        fontFamily="Montserrat"
                      >
                        {formateDate(data.liquidate_date)}
                      </Text>
                    </Flex>
                  </Box>
                </Collapse>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <HStack
        spacing="6px"
        padding={'16px'}
        borderRadius={'44px'}
        border={'1px solid #EFEFEF'}
        background={'#FFF'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
        width={'fit-content'}
        alignSelf={'center'}
        marginTop={'20px'}
      >
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage <= 1}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />

        {renderPageNumbers()}

        <IconButton
          aria-label="Next page"
          icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage >= totalPages}
          variant="ghost"
          _hover={{
            bg: 'gray.100',
            borderRadius: 'full',
          }}
          transition="all 0.2s"
        />
      </HStack>
    </>
  );
};

export default LiquidationTable;
