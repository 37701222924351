import { Box, Text } from '@chakra-ui/react';
import { ChartOptions } from 'chart.js';
import 'chart.js/auto';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import Dollar from '../../assets/Dollar.svg';
import Info from '../../assets/Info.svg';
import DashedLine from './DashedLine';
import { Liquidate } from '../../interfaces/components';
import { getLiquidation } from '../../api/InvestorApis/liquidity';

interface GraphDataItem {
  total: number;
  change: number;
}

const Graph: React.FC = () => {
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(0);
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>(6);
  const [hoveredDayIndex, setHoveredDayIndex] = useState<number | null>(null);
  const timeRanges: string[] = ['7 Days', '30 Days', '90 Days', 'All'];
  const [dates, setDates] = useState<Date[]>([]);
  const [graphData, setGraphData] = useState<GraphDataItem[]>([]);
  const [yAxisMax, setYAxisMax] = useState<number>(500);
  const [yAxisStep, setYAxisStep] = useState<number>(100);
  const [liquidateData, setLiquidateData] = useState<Liquidate>();

  const fetchLiquidationData = async () => {
    const liquidationData = await getLiquidation(null);
    setLiquidateData(liquidationData);
  };

  useEffect(() => {
    fetchLiquidationData();
  }, []);

  useEffect(() => {
    const profitValue = liquidateData?.results?.map((item) => item.profit);
    const liquidateDates = liquidateData?.results?.map(
      (item) => new Date(item.liquidate_date),
    );
    console.log(liquidateDates);
    const generateDataFromProfits = (
      profitArray: number[],
    ): GraphDataItem[] => {
      return profitArray.map((currentProfit, index) => {
        const prevProfit = index === 0 ? -1 : profitArray[index - 1];
        const change = prevProfit === -1 ? 0 : currentProfit - prevProfit;
        return {
          total: currentProfit,
          change,
          date: liquidateDates ? liquidateDates[index] ?? null : null,
        };
      });
    };
    const today = new Date();
    let daysToGenerate = 7;

    switch (selectedTimeIndex) {
      case 0:
        daysToGenerate = 7;
        break;
      case 1:
        daysToGenerate = 30;
        break;
      case 2:
        daysToGenerate = 90;
        break;
      case 3:
        daysToGenerate = 120;
        break;
      default:
        daysToGenerate = 7;
    }
    const newDates = Array.from({ length: daysToGenerate }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - daysToGenerate + 1 + i);
      return date;
    });
    const filterDataByDays = (
      data: GraphDataItem[],
      dates: Date[],
      days: number,
    ) => {
      const today = new Date();

      return data.filter((_, index) => {
        const date = dates[index];

        const timeDifference = today.getTime() - date.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);

        return dayDifference <= days;
      });
    };

    setDates(newDates);
    setSelectedDayIndex(daysToGenerate - 1);

    const newGraphData = generateDataFromProfits(profitValue ?? []);

    const filterdata = filterDataByDays(
      newGraphData,
      liquidateDates ?? [],
      daysToGenerate,
    );
    setGraphData(filterdata);
    console.log(graphData);
    console.log(filterdata);
    const maxTotal = Math.max(...newGraphData.map((item) => item.total));
    const newYAxisMax = Math.ceil(maxTotal / 100) * 100;
    setYAxisMax(newYAxisMax);

    const newYAxisStep = Math.max(1, Math.floor(newYAxisMax / 5));
    setYAxisStep(newYAxisStep);
  }, [selectedTimeIndex, liquidateData]);

  const selectedDayChange = graphData[selectedDayIndex]?.change || 0;
  const isSelectedDayProfit = selectedDayChange >= 0;

  const data = {
    labels: dates.map((date) =>
      date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }),
    ),
    datasets: [
      {
        label: 'Total Amount',
        data: graphData.map((item) => item.total),
        fill: true,
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(
            0,
            isSelectedDayProfit
              ? 'rgba(57, 208, 93, 0.5)'
              : 'rgba(255, 99, 132, 0.5)',
          );
          gradient.addColorStop(
            1,
            isSelectedDayProfit
              ? 'rgba(57, 208, 93, 0)'
              : 'rgba(255, 99, 132, 0)',
          );
          return gradient;
        },
        borderColor: isSelectedDayProfit ? '#39D05D' : '#FF6384',
        borderWidth: 2,
        tension: 0,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    scales: {
      x: {
        grid: { display: false },
        title: { display: false },
        ticks: { display: false },
        border: { display: false },
      },
      y: {
        position: 'right',
        grid: { display: false },
        border: { display: false },
        ticks: {
          color: '#8C8C8C',
          font: {
            family: 'Montserrat',
            size: 16,
            weight: 500,
          },
          stepSize: yAxisStep,
          callback: function (value: string | number) {
            const numericValue = typeof value === 'string' ? parseFloat(value) : value;
            return numericValue % yAxisStep === 0 ? numericValue : null;
          },
          padding: 10,
        },
        min: Math.min(0, Math.floor(Math.min(...graphData.map((item) => item.total)) / 100) * 100),
        max: yAxisMax,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        position: 'nearest',
        intersect: false,
        backgroundColor: '#FFF',
        titleColor: '#A6A6A6',
        titleFont: {
          family: 'Montserrat',
          size: 16,
          weight: 500,
        },
        bodyFont: {
          family: 'Montserrat',
          size: 16,
          weight: 600,
        },
        padding: 12,
        displayColors: false,
        borderColor: '#E2E2E2',
        borderWidth: 0.5,
        cornerRadius: 16,
        caretSize: 0,
        yAlign: 'bottom',
        caretPadding: 10,
        callbacks: {
          title: function (tooltipItems: any[]) {
            const index = tooltipItems[0].dataIndex;
            const date = dates[index];
            return date.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            });
          },
          label: function (context: any) {
            const dataIndex = context.dataIndex;
            const change = graphData[dataIndex]?.change || 0;
            const isProfit = change >= 0;
            const prefix = isProfit ? 'Profit: $' : 'Loss: $';
            const value = Math.abs(change).toFixed(2);
            return prefix + value;
          },
          labelColor: function (context: any) {
            const dataIndex = context.dataIndex;
            const change = graphData[dataIndex]?.change || 0;
            return {
              borderColor: change >= 0 ? '#39D05D' : '#FF6384',
              backgroundColor: change >= 0 ? '#39D05D' : '#FF6384',
            };
          },
          labelTextColor: function (context: any) {
            const dataIndex = context.dataIndex;
            const change = graphData[dataIndex]?.change || 0;
            return change >= 0 ? '#39D05D' : '#FF6384';
          },
        },
      },
    },
    elements: {
      line: { borderWidth: 2, tension: 0 },
      point: { radius: 0 },
    },
    hover: {
      mode: 'nearest' as const,
      intersect: false,
    },
  };
  

  return (
    <Box
      width="647px"
      display="flex"
      padding="22px"
      flexDirection="column"
      gap="16px"
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius="44px"
      border="1px solid"
      borderColor="#F6F6F6"
      backgroundColor="#FFF"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        rowGap="16px"
        alignSelf="stretch"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center" gap="8px">
          <img src={Dollar} alt="Dollar Icon" />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="4px"
          >
            <Text
              color="#1A1A1A"
              textAlign="center"
              fontFamily="Visby CF, sans-serif"
              fontSize="24px"
              fontWeight="600"
              lineHeight="normal"
            >
              Profit/Loss History
            </Text>
            <img src={Info} alt="Info Icon" />
          </Box>
        </Box>
        <Box
          display="flex"
          padding="4px"
          alignItems="flex-start"
          borderRadius="24px"
          background="#F2F2F2"
        >
          {timeRanges.map((option, index) => (
            <Box
              key={index}
              display="flex"
              padding="10px 20px"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              borderRadius="20px"
              background={selectedTimeIndex === index ? '#FFF' : '#F2F2F2'}
              onClick={() => setSelectedTimeIndex(index)}
              cursor="pointer"
            >
              <Text
                color={selectedTimeIndex === index ? '#0FBF95' : '#000'}
                fontFamily="Montserrat"
                fontSize="16px"
                fontStyle="normal"
                margin="0"
                fontWeight="600"
                lineHeight="20px"
              >
                {option}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        padding="20px"
        background="#FFF"
        borderRadius="8px"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          gap="8px"
        >
          <Text
            color={isSelectedDayProfit ? '#39D05D' : '#FF6384'}
            fontFamily="SF Pro Text"
            fontSize="42px"
            fontStyle="normal"
            fontWeight="600"
            margin="0"
            lineHeight="52px"
            letterSpacing="-1.26px"
          >
            ${Math.abs(selectedDayChange).toFixed(2)}
          </Text>
          <Text
            color="#8C8C8C"
            textAlign="center"
            fontFamily="Montserrat"
            fontSize="18px"
            fontStyle="normal"
            margin="0"
            fontWeight="500"
            lineHeight="18px"
          >
            {isSelectedDayProfit ? 'Profit' : 'Loss'} on{' '}
            {dates[selectedDayIndex]?.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            }) || ''}
          </Text>
        </Box>
        <Text
          color="#8C8C8C"
          margin="0"
          textAlign="center"
          fontFamily="Montserrat"
          fontSize="18px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="18px"
        >
          {dates[selectedDayIndex]?.toLocaleString('default', {
            month: 'long',
            year: 'numeric',
          })}
        </Text>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box position={'relative'} flex="1" height="100%">
          <DashedLine top="12px" left="0" />
          <DashedLine top="70px" left="0" />
          <DashedLine top="131px" left="0" />
          <Line data={data} options={options} height={123} />
          <DashedLine bottom="70px" left="0" />
          <DashedLine bottom="12px" left="0" />
        </Box>
      </Box>
      <Box position="relative" alignSelf="stretch" width={'610px'}>
        <Box
          display="flex"
          alignItems="center"
          alignSelf="stretch"
          flexDirection="row"
          gap="10px"
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': { width: '0px', height: '0px' },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }}
        >
          <Box
            display="flex"
            height="fit-content"
            flexDirection="row"
            alignItems="center"
            gap="10px"
          >
            {dates.map((date, index) => (
              <Box
                key={index}
                display="flex"
                width={'75.5px'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex="1 0 0"
              >
                <Box
                  display="flex"
                  width="60px"
                  height="var(--Size-Height-controlHeight, 32px)"
                  minWidth="60px"
                  padding="3px 6px"
                  justifyContent="center"
                  alignItems="center"
                  gap="4px"
                  flexShrink="0"
                  borderRadius="20px"
                  background={
                    selectedDayIndex === index
                      ? '#1A1A1A'
                      : hoveredDayIndex === index
                      ? '#D1F5EB'
                      : '#FFF'
                  }
                  color={
                    selectedDayIndex === index
                      ? '#FFF'
                      : hoveredDayIndex === index
                      ? '#0FBF95'
                      : '#8C8C8C'
                  }
                  textAlign="center"
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="16px"
                  onClick={() => setSelectedDayIndex(index)}
                  onMouseEnter={() => setHoveredDayIndex(index)}
                  onMouseLeave={() => setHoveredDayIndex(null)}
                  cursor="pointer"
                >
                  <Text>
                    {date.toLocaleDateString('en-US', {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Graph;
