import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { ClientInfoResponse } from '../../interfaces/components';

interface Investment {
  name: string;
  percentage: number;
  adjustedPercentage: number;
  color: string;
}

const Chart: React.FC<{ clientInfo: ClientInfoResponse | undefined }> = ({
  clientInfo,
}) => {
  const total_tokens = clientInfo?.total_tokens || 0;

  const allInvestments: Omit<Investment, 'adjustedPercentage'>[] =
    clientInfo?.results
      ? clientInfo.results.map((client) => {
          const percentage = (client.total_tokens / total_tokens) * 100;
          return {
            name: client.client.name,
            percentage: parseFloat(percentage.toFixed(0)) || 0,
            color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          };
        })
      : [];
  console.log(allInvestments);

  const adjustedInvestments = useMemo(() => {
    const totalPercentage = allInvestments.reduce(
      (sum, inv) => sum + inv.percentage,
      0,
    );
    const scaleFactor = 100 / totalPercentage;

    return allInvestments?.map((inv) => ({
      ...inv,
      adjustedPercentage: +(inv.percentage * scaleFactor).toFixed(2),
    }));
  }, []);

  const highestValueInvestment = useMemo(() => {
    return adjustedInvestments.reduce(
      (max, current) => (current.percentage > max.percentage ? current : max),
      adjustedInvestments[0],
    );
  }, [adjustedInvestments]);

  const [hoveredInvestment, setHoveredInvestment] = useState<Investment | null>(
    highestValueInvestment,
  );
  const [currentPage, setCurrentPage] = useState(0);

  const totalPages = Math.ceil(adjustedInvestments.length / 5);

  const currentInvestments = useMemo(() => {
    return adjustedInvestments.slice(currentPage * 5, (currentPage + 1) * 5);
  }, [adjustedInvestments, currentPage]);

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  let startAngle = 0;
  const gap = 15; // Reduced gap for a more complete circle

  return (
    <Box
      borderBottomRadius={'44px'}
      border={'1px solid #EFEFEF'}
      background={'#FFF'}
      boxShadow={
        '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
      }
      padding={'22px'}
      height={['none', 'none', 'none', '500px']}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'24px'}
        padding={['0px', '0px', '24px 24px 0px 24px']}
      >
        <Flex gap={'8px'} justifyContent={'center'}>
          <Text
            fontSize={['13.5px', '14px', '14px', '16px']}
            fontWeight={'500'}
            lineHeight={'24px'}
            fontFamily="Montserrat"
          >
            {adjustedInvestments.length} active investments
          </Text>
          <Text
            fontSize={['13.5px', '14px', '14px', '16px']}
            fontWeight={'500'}
            lineHeight={'24px'}
            color="#878787"
            fontFamily="Montserrat"
          >
            Today, 25 Sept, 2024.
          </Text>
        </Flex>

        <Flex
          gap={['24px', '24px', '24px', '56px']}
          flexDir={['column', 'column', 'column', 'row']}
        >
          <Flex
            justify="center"
            align="center"
            position="relative"
            padding={['14.6px', '14.6px', '14.6px', '0px']}
          >
            <svg viewBox="0 0 100 100" width="100%" height="100%">
              <circle
                cx="50"
                cy="50"
                r="45"
                fill="none"
                stroke="transparent"
                strokeWidth="10"
              />
              {adjustedInvestments.map((investment, index) => {
                const angle =
                  (investment.adjustedPercentage / 100) *
                  (360 - gap * adjustedInvestments.length);
                const endAngle = startAngle + angle;

                const start = polarToCartesian(50, 50, 45, startAngle - 90);
                const end = polarToCartesian(50, 50, 45, endAngle - 90);

                const largeArcFlag = angle > 180 ? 1 : 0;

                const path = [
                  'M',
                  start.x,
                  start.y,
                  'A',
                  45,
                  45,
                  0,
                  largeArcFlag,
                  1,
                  end.x,
                  end.y,
                ].join(' ');

                startAngle = endAngle + gap;

                return (
                  <path
                    key={index}
                    d={path}
                    fill="none"
                    stroke={investment.color}
                    strokeWidth="10"
                    strokeLinecap="round"
                    onMouseEnter={() => setHoveredInvestment(investment)}
                    onMouseLeave={() =>
                      setHoveredInvestment(highestValueInvestment)
                    }
                    style={{ cursor: 'pointer' }}
                  />
                );
              })}
            </svg>
            <VStack position="absolute" spacing={0} textAlign="center">
              <Text
                fontSize="28px"
                fontWeight="700"
                lineHeight={'41.181px'}
                color={
                  (hoveredInvestment?.color ?? '') ||
                  (highestValueInvestment?.color ?? '')
                }
              >
                +
                {hoveredInvestment
                  ? `${hoveredInvestment?.percentage}%`
                  : `${highestValueInvestment?.percentage}%`}
              </Text>
              <Text
                fontSize="14px"
                fontWeight="500"
                color={'#080808'}
                lineHeight={'19.007px'}
              >
                {hoveredInvestment?.name || highestValueInvestment?.name}
              </Text>
              <Text
                fontSize="14px"
                color="#878787"
                fontWeight={'500'}
                lineHeight={'23.75px'}
              >
                Proportional
              </Text>
            </VStack>
          </Flex>

          <VStack
            align="stretch"
            width={'307px'}
            height={['fit-content', '242px']}
            justifyContent={['flex-start', 'center']}
          >
            {currentInvestments.map((investment, index) => (
              <HStack
                key={index}
                justifyContent={'space-between'}
                margin={'0px'}
                onMouseEnter={() => setHoveredInvestment(investment)}
                onMouseLeave={() =>
                  setHoveredInvestment(highestValueInvestment)
                }
                style={{ cursor: 'pointer' }}
              >
                <HStack>
                  <Box
                    w={'6px'}
                    h={'12px'}
                    borderRadius={'50px'}
                    bg={investment.color}
                  />
                  <Text fontSize="14px" color={'#878787'} fontWeight={'500'}>
                    {investment.name}
                  </Text>
                </HStack>
                <Text fontSize="14px" fontWeight="600" color={'#080808'}>
                  {investment.percentage}%
                </Text>
              </HStack>
            ))}
          </VStack>
        </Flex>
        <HStack justifyContent="flex-end">
          <Box
            display={'flex'}
            padding={'6px'}
            border={'1px solid #EFEFEF'}
            borderRadius={'44px'}
            backgroundAttachment={'#FFF'}
            gap={'12px'}
          >
            <Button
              variant="ghost"
              onClick={prevPage}
              borderRadius={'100px'}
              padding={'0px'}
              isDisabled={currentPage === 0}
            >
              <ChevronLeftIcon
                width={'32px'}
                height={'32px'}
                color={currentPage === 0 ? '#E2E2E2' : '#8C8C8C'}
              />
            </Button>

            <Button
              variant="ghost"
              onClick={nextPage}
              borderRadius={'100px'}
              padding={'0px'}
              isDisabled={currentPage === totalPages - 1}
            >
              <ChevronRightIcon
                width={'32px'}
                height={'32px'}
                color={currentPage === totalPages - 1 ? '#E2E2E2' : '#8C8C8C'}
              />
            </Button>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

export default Chart;
