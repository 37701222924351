import React from 'react';

interface iProp {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
}

const DashedLine: React.FC<iProp> = ({ top, left, right, bottom }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: top || 'auto', // Default to 'auto' if not provided
        left: left || 'auto', // Default to 'auto' if not provided
        right: right || 'auto', // Default to 'auto' if not provided
        bottom: bottom || 'auto', // Default to 'auto' if not provided
        // width: '601px',
        height: '0.5px',
        borderTop: '0.5px dashed var(--Color-Neutral-200, #E2E2E2)',
      }}
    ></div>
  );
};

export default DashedLine;
